// src/mixins/subscribeNewsletterMixin.js
import Axios from 'axios';

export default {
  data () {
    return {
      email: null,
      errorEmail: []
    };
  },
  methods: {
    validEmail: function (email) {
      const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    resetErrors () {
      this.errorEmail = [];
    },
    reset () {
      this.email = null;
    },
    sendDataNewsLayerInfo () {
      this.trackUserData('generate_lead', {
        lead_type: 'newsletter'
      });
    },
    subscribeNewsletter () {
      this.resetErrors();
      if (this.email && this.validEmail(this.email)) {
        this.loading = true;
        const data = {
          email: this.email
        };
        const url = `${process.env.VUE_APP_BASE_URL}/api/newsletter-subscriptions`;
        Axios
          .post(url, data)
          .then(() => {
            this.sendDataNewsLayerInfo();
            this.reset();
            this.$swal.fire({
              title: this.$t('awesome'),
              text: this.$t('thankForSubscribe'),
              confirmButtonColor: '#19AD93'
            });
            this.email = '';
            this.loading = false;
          })
          .catch((error) => {
            this.email = '';
            this.loading = false;
            this.showError(error);
          });
      }
      if (!this.email) {
        this.errorEmail.push(this.$t('emailRequired'));
      }
      if (!this.validEmail(this.email)) {
        this.errorEmail.push(this.$t('enterValidEmail'));
      }
    }
  }
};
